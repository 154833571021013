// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OwEnvWUcy5AvaswyxIj1{height:100%;width:100%;background:#000;font-family:helvetica,arial,sans-serif;display:flex;flex-direction:column;align-items:center;justify-content:center}.OwEnvWUcy5AvaswyxIj1 img{width:33.33vmin}.OwEnvWUcy5AvaswyxIj1 .i6LklCHGOblgw9bcszph{margin-top:.2em;font-size:13.33vmin;font-weight:bold;line-height:1.5em;background:linear-gradient(249.83deg, #FFFF80 15.08%, #20FFFF 68.5%);-webkit-background-clip:text;-webkit-text-fill-color:rgba(0,0,0,0);background-clip:text}.OwEnvWUcy5AvaswyxIj1 .ExdY9Wkek_gx2rko0vey{position:fixed;bottom:24px;width:100%;font-size:6.6vmin;text-align:center;line-height:1.5em;color:#999}", "",{"version":3,"sources":["webpack://./components/App.module.sass"],"names":[],"mappings":"AAGA,sBACE,WAAA,CACA,UAAA,CACA,eANW,CAOX,sCAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAEA,0BACE,eAAA,CAEF,4CACE,eAAA,CACA,mBAAA,CACA,gBAAA,CACA,iBAAA,CACA,oEAAA,CACA,4BAAA,CACA,qCAAA,CACA,oBAAA,CAEF,4CACE,cAAA,CACA,WAAA,CACA,UAAA,CACA,iBAAA,CACA,iBAAA,CACA,iBAAA,CACA,UAhCG","sourcesContent":["$background: #000\n$soon: #999\n\n.App\n  height: 100%\n  width: 100%\n  background: $background\n  font-family: helvetica, arial, sans-serif\n  display: flex\n  flex-direction: column\n  align-items: center\n  justify-content: center\n\n  img\n    width: 33.33vmin\n\n  .name\n    margin-top: 0.2em\n    font-size: 13.33vmin\n    font-weight: bold\n    line-height: 1.5em\n    background: linear-gradient(249.83deg, #FFFF80 15.08%, #20FFFF 68.5%)\n    -webkit-background-clip: text\n    -webkit-text-fill-color: transparent\n    background-clip: text\n\n  .soon\n    position: fixed\n    bottom: 24px\n    width: 100%\n    font-size: 6.6vmin\n    text-align: center\n    line-height: 1.5em\n    color: $soon\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": "OwEnvWUcy5AvaswyxIj1",
	"name": "i6LklCHGOblgw9bcszph",
	"soon": "ExdY9Wkek_gx2rko0vey"
};
export default ___CSS_LOADER_EXPORT___;
